var prodcat = prodcat || {};
var generic = generic || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.regimenBuilder = {
    attached: false,
    isDesktop: false,
    nodes: {},

    selectors: {
      regimenBuilderBlock: '.js-elc-regimen-builder-block-v1',
      product: '.js-product',
      productCarousel: '.js-elc-regimen-product-carousel',
      productItemHeadline: '.js-elc-regimen-product-item-headline',
      productItemContent: '.js-elc-regimen-product-item-content',
      productCheckbox: '.js-elc-regimen-product-checkbox',
      productImage: '.js-elc-regimen-product-image',
      productTitle: '.js-elc-regimen-product-title',
      productSkuLabel: '.js-elc-regimen-product-size-label, .js-elc-regimen-product-shade-label',
      productSizeDropdown: '.js-elc-regimen-product-sizes-dropdown',
      productShadeDropdown: '.js-elc-regimen-product-shades-dropdown',
      productShadeDropdownSelector: '.js-elc-regimen-product-shades-dropdown-selector',
      productShadeDropdownColor: '.js-elc-regimen-product-shades-dropdown-color',
      productRating: '.js-elc-regimen-product-rating',
      productRatingAverage: '.js-elc-regimen-product-rating-average',
      productRatingStars: '.js-elc-regimen-product-rating-stars',
      productRatingTotal: '.js-elc-regimen-product-rating-total',
      productPrice: '.js-formatted-price',
      productPrice2: '.js-formatted-price2',
      productUnitPrice: '.js-formatted-unit-price',
      productInventoryStatus: '.js-elc-regimen-product-inventory-status',
      totalPrice: '.js-elc-regimen-total-price .js-mantle-custom-text',
      atbCta: '.js-elc-regimen-atb-cta .js-elc-button',
      atbCtaSingular: '.js-elc-regimen-atb-cta-singular .js-elc-button',
      atbCtaNoItems: '.js-elc-regimen-atb-cta-no-items .js-elc-button'
    },

    productShadeDropdownSelectboxClasses: {
      classSelector: 'js-elc-regimen-product-shades-dropdown-selector',
      classColorSpan: 'elc-regimen-builder-block__product-sku-select-shades-color js-elc-regimen-product-shades-dropdown-color',
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.updateIsDesktop();

      $(self.selectors.regimenBuilderBlock, context).each(function () {
        self.setup($(this));
      });
    },

    setup: function ($that) {
      var self = this;

      self.getDom($that);
      self.setEvents($that);
      self.init($that);
    },

    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$product = $(self.selectors.product, $that);
      $that.nodes.$selectedProducts = $that.nodes.$product;
      $that.nodes.$productCarousel = $(self.selectors.productCarousel, $that);
      $that.nodes.$productItemHeadline = $(self.selectors.productItemHeadline, $that);
      $that.nodes.$productItemContent = $(self.selectors.productItemContent, $that);
      $that.nodes.$productCheckbox = $(self.selectors.productCheckbox, $that);
      $that.nodes.$productImage = $(self.selectors.productImage, $that);
      $that.nodes.$productTitle = $(self.selectors.productTitle, $that);
      $that.nodes.$productSkuLabel = $(self.selectors.productSkuLabel, $that);
      $that.nodes.$productSizeDropdown = $(self.selectors.productSizeDropdown, $that);
      $that.nodes.$productShadeDropdown = $(self.selectors.productShadeDropdown, $that);
      $that.nodes.$productRating = $(self.selectors.productRating, $that);
      $that.nodes.$totalPrice = $(self.selectors.totalPrice, $that);
      $that.nodes.$atbCta = $(self.selectors.atbCta, $that);
      $that.nodes.$atbCtaSingular = $(self.selectors.atbCtaSingular, $that);
      $that.nodes.$atbCtaNoItems = $(self.selectors.atbCtaNoItems, $that);
      
      $that.totalPriceText = $that.nodes.$totalPrice.text();
      $that.atbCtaText = $that.nodes.$atbCta.text();
      $that.atbCtaSingularText = $that.nodes.$atbCtaSingular.text();
      $that.atbCtaNoItemsText = $that.nodes.$atbCtaNoItems.text();
    },

    setEvents: function ($that) {
      var self = this;

      $that.nodes.$productCheckbox.once().on('change', function () {
        $that.nodes.$selectedProducts = $that.nodes.$productCheckbox.filter(':checked').closest(self.selectors.product);
        self.updateTotalPrice($that);
        self.updateSelectedProductsCount($that);
      });

      $that.nodes.$productImage.once().on('click', function (e) {
        var $product = $(this).closest(self.selectors.product);
        var $productCheckbox = $product.find(self.selectors.productCheckbox);

        if (!self.isDesktop) {
          e.preventDefault();
          $productCheckbox.prop('checked', !$productCheckbox.prop('checked')).trigger('change');
        }
      });

      $that.nodes.$productSkuLabel.once().on('click', function (e) {
        e.preventDefault();
      });

      $that.nodes.$atbCta.once().on('click', function (e) {
        e.preventDefault();
        self.addSelectedProductsToBag($that);
      });
      
      $that.nodes.$atbCtaSingular.once().on('click', function (e) {
        e.preventDefault();
        self.addSelectedProductsToBag($that);
      });
      $that.nodes.$atbCtaNoItems.once().on('click', function (e) {
        e.preventDefault();
      });

      $(window).on(
        'resize',
        _.debounce(function () {
          self.updateIsDesktop();

          if (self.isDesktop) {
            self.equalHeights($that.nodes.$productItemHeadline);
            self.equalHeights($that.nodes.$productItemContent);
            self.equalHeights($that.nodes.$productTitle);
            self.equalHeights($that.nodes.$productRating);
            self.initCarousel($that);
          } else {
            $that.nodes.$productTitle.height('auto');
            $that.nodes.$productRating.height('auto');
            $that.nodes.$productCarousel.filter('.slick-initialized').slick('destroy');
          }
        }, 100)
      );
    },

    init: function ($that) {
      var self = this;

      $that.nodes.$product.each(function () {
        var $product = $(this);

        self.updateProductInventoryStatus($product);
        self.initProductPrices($that, $product);
        self.initProductSelectbox($that, $product);
      });
      self.initProductsRating($that);
      self.updateProductsCount($that);
      self.updateSelectedProductsCount($that);
      self.updateTotalPrice($that);
      self.initCarousel($that);

      if (self.isDesktop) {
        self.equalHeights($that.nodes.$productItemHeadline);
        self.equalHeights($that.nodes.$productItemContent);
        self.equalHeights($that.nodes.$productTitle);
      }

      $that.nodes.$atbCtaNoItems.addClass('elc-button--disabled');
      $that.addClass('elc-regimen-block-initialized');
    },

    initCarousel: function ($that) {
      var self = this;
      var productsCount = $that.nodes.$product.length;
      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4
      };
      
      if (self.isDesktop && productsCount > 4) {
        $that.nodes.$productCarousel.filter(':not(.slick-initialized)').each(function () {
          var $carousel = $(this);
  
          $carousel.slick(settings);
        });
      }
    },

    initProductPrices: function ($that, $product) {
      var self = this;
      var $productPrice = $product.find(self.selectors.productPrice);
      var $productPrice2 = $product.find(self.selectors.productPrice2);
      var $productUnitPrice = $product.find(self.selectors.productUnitPrice);
      var skuPrice = $productPrice.attr('data-sku-price');
      var skuPrice2 = $productPrice2.attr('data-sku-price2');

      $productPrice.text(self.getFormattedPrice($that, skuPrice));
      $productPrice2.text(!!skuPrice2 ? self.getFormattedPrice($that, skuPrice2) : '');
      !!$productPrice2.text() && $productPrice2.removeClass('hidden');

      if (Drupal.settings && !Drupal.settings.hide_product_price_per_unit) {
        !!$productUnitPrice.text() && $productUnitPrice.removeClass('hidden');
      }
    },

    initProductsRating: function ($that) {
      var self = this;

      new Promise((resolve, reject) => {
        var url = Drupal.settings.power_reviews.snippet_url;
        var apiKey = Drupal.settings.power_reviews.api_key;
        var merchantId = Drupal.settings.power_reviews.merchant_id;
        var language = Drupal.settings.power_reviews.locale;
        var productIds = $that.nodes.$product.toArray().map((product) => $(product).attr('data-prod-id')).join(',').replaceAll('PROD', '');

        url = url.replace('MERCHANT_ID', merchantId)
          .replace('PRODUCT_IDS', productIds)
          .replace('API_KEY', apiKey)
          .replace('LOCALE_LANGUAGE', language);
        $.ajax(url)
          .done(function (r) {
            resolve(r);
          })
          .fail(function (e) {
            reject(e);
          });
      }).then((data) => {
        $that.nodes.$product.each(function () {
          var $product = $(this);
          var $productRatingAverage = $product.find(self.selectors.productRatingAverage);
          var $productRatingStars = $product.find(self.selectors.productRatingStars);
          var $productRatingTotal = $product.find(self.selectors.productRatingTotal);
          var productId = $product.attr('data-prod-id').replaceAll('PROD', '');
          var reviewsData = data.results.filter((review) => (
            parseInt(review.page_id) === parseInt(productId) && review.rollup
          ));
          var averageRating = 0;
          var ratingPercentage = 0;

          if (reviewsData.length > 0) {
            averageRating = reviewsData[0].rollup.average_rating || 0;
            ratingPercentage = Math.round((averageRating / 5) * 100);
            $productRatingStars.css('width', `${ratingPercentage}%`);
            $productRatingAverage.attr('data-rating-average', averageRating);
            $productRatingTotal.text(`(${reviewsData[0].rollup.review_count})`);
          }
          if (!$productRatingAverage.attr('data-rating-average')) {
            $productRatingAverage.addClass('hidden');
            $productRatingTotal.addClass('hidden');
          }
        });
        if (self.isDesktop) {
          self.equalHeights($that.nodes.$productRating);
        }
      });
    },

    initProductSelectbox: function ($that, $product) {
      var self = this;
      var $productSizeDropdown = $product.find(self.selectors.productSizeDropdown);
      var $productShadeDropdown = $product.find(self.selectors.productShadeDropdown);

      $productSizeDropdown.selectbox({
        onOpen: function () {
          $that.nodes.$productSizeDropdown.not($productSizeDropdown).selectbox('close');
          $that.nodes.$productShadeDropdown.selectbox('close');
        },
        onChange: function (val) {
          var $selectedOption = $(this).find(`option[value='${val}']`);
          
          self.updateProductSku($that, $selectedOption);
          self.updateTotalPrice($that);
        },
        loopOptions: true,
        keepInViewport: true,
        effect: 'slide'
      });

      $productShadeDropdown.selectbox({
        classSelector: `sbSelector ${self.productShadeDropdownSelectboxClasses.classSelector}`,
        onOpen: function () {
          var $selectMenuOptions = $('li a', $productShadeDropdown.next());

          if ($selectMenuOptions.find(self.selectors.productShadeDropdownColor).length <= 0) {
            $selectMenuOptions.each(function () {
              var $option = $(this);
              var $productShadeDropdownColorSpan = $('<span/>').addClass(self.productShadeDropdownSelectboxClasses.classColorSpan);
              var shadeColor = $productShadeDropdown.find(`option[value='${$option.attr('rel')}']`).attr('data-sku-color');

              $option.prepend($productShadeDropdownColorSpan.css('background-color', shadeColor));
            });
          }

          $that.nodes.$productSizeDropdown.selectbox('close');
          $that.nodes.$productShadeDropdown.not($productShadeDropdown).selectbox('close');
        },
        onChange: function (val) {
          var $selectedOption = $(this).find(`option[value='${val}']`);

          self.updateProductShadeDropdownSelectorColor($productShadeDropdown);
          self.updateProductSku($that, $selectedOption);
          self.updateTotalPrice($that);
        },
        loopOptions: true,
        keepInViewport: true,
        effect: 'slide'
      });
      
      self.updateProductShadeDropdownSelectorColor($productShadeDropdown);
    },

    updateProductInventoryStatus: function ($product) {
      var self = this;
      var $productCheckbox = $product.find(self.selectors.productCheckbox);
      var $productInventoryStatus = $product.find(self.selectors.productInventoryStatus);
      var skuInventoryStatus = $product.attr('data-inventory-status');
      var skuIsOrderable = $product.attr('data-is-orderable');

      if (parseInt(skuIsOrderable, 10) === 0) {
        $productCheckbox.attr('disabled', 'disabled').prop('checked', false).trigger('change');
      } else if ($productCheckbox.attr('disabled')) {
        $productCheckbox.removeAttr('disabled').prop('checked', true).trigger('change');
      }

      switch (parseInt(skuInventoryStatus, 10)) {
        case 2:
          $productInventoryStatus.removeClass('hidden').text(site.translations.elc_product.item_is_temp_out_of_stock);
          break;
        case 3:
          $productInventoryStatus.removeClass('hidden').text(site.translations.elc_product.item_is_coming_soon_notify_me);
          break;
        case 7:
          $productInventoryStatus.removeClass('hidden').text(site.translations.elc_product.item_is_sold_out);
          break;
        default:
          $productInventoryStatus.addClass('hidden').text('');
          break;
      }
    },

    updateProductShadeDropdownSelectorColor: function ($productShadeDropdown) {
      var self = this;
      var $productShadeDropdownSelector = $productShadeDropdown.next().find(self.selectors.productShadeDropdownSelector);
      var $productShadeDropdownSelectedOption = $productShadeDropdown.find(':selected');
      var $productShadeDropdownColorSpan = $('<span/>').addClass(self.productShadeDropdownSelectboxClasses.classColorSpan);
      var productShadeDropdownSelectedOptionColor = $productShadeDropdownSelectedOption.attr('data-sku-color');

      $productShadeDropdownSelector.prepend($productShadeDropdownColorSpan.css('background-color', productShadeDropdownSelectedOptionColor));
    },

    updateProductSku: function ($that, $selectedOption) {
      var self = this;
      var $product = $selectedOption.closest(self.selectors.product);
      var $productImage = $product.find(self.selectors.productImage);
      var $productPrice = $product.find(self.selectors.productPrice);
      var $productPrice2 = $product.find(self.selectors.productPrice2);
      var $productUnitPrice = $product.find(self.selectors.productUnitPrice);
      var skuBaseId = $selectedOption.attr('data-sku-base-id');
      var skuInventoryStatus = $selectedOption.attr('data-sku-inventory-status');
      var skuIsOrderable = $selectedOption.attr('data-sku-is-orderable');
      var skuImage = $selectedOption.attr('data-sku-image');
      var skuPrice = $selectedOption.attr('data-sku-price');
      var skuPrice2 = $selectedOption.attr('data-sku-price2');
      var skuFormattedPrice = $selectedOption.attr('data-sku-formatted-price');
      var skuUnitPrice = $selectedOption.attr('data-sku-formatted-unit-price');

      $product.attr('data-sku-base-id', skuBaseId)
        .attr('data-inventory-status', skuInventoryStatus)
        .attr('data-is-orderable', skuIsOrderable)
        .attr('data-price', skuPrice)
        .attr('data-price2', skuPrice2)
        .attr('data-formatted-price', skuFormattedPrice);
      $productImage.attr('src', skuImage);
      $productPrice.attr('data-sku-price', skuPrice)
        .text(self.getFormattedPrice($that, skuPrice));
      $productPrice2.attr('data-sku-price2', skuPrice2)
        .text(!!skuPrice2 ? self.getFormattedPrice($that, skuPrice2) : '');
      !!$productPrice2.text() ? $productPrice2.removeClass('hidden') : $productPrice2.addClass('hidden');

      if (Drupal.settings && !Drupal.settings.hide_product_price_per_unit) {
        !!skuUnitPrice ? $productUnitPrice.removeClass('hidden').text(skuUnitPrice) : $productUnitPrice.addClass('hidden').text('');
      }
      
      self.updateProductInventoryStatus($product);
    },

    updateTotalPrice: function ($that) {
      var self = this;
      var totalPrice = 0;
      var updatedTotalPriceText = '';

      $that.nodes.$selectedProducts.each(function () {
        totalPrice += parseFloat($(this).attr('data-price'));
      });
      
      updatedTotalPriceText = $that.totalPriceText.replace('{total_price}', self.getFormattedPrice($that, totalPrice));
      $that.nodes.$totalPrice.text(updatedTotalPriceText);
    },

    updateProductsCount: function ($that) {
      var productsCount = $that.nodes.$product.length;

      $that.attr('data-products-count', productsCount);
    },

    updateSelectedProductsCount: function ($that) {
      var selectedProductsCount = $that.nodes.$selectedProducts.length;
      var updatedAtbCtaText = $that.atbCtaText.replace('{selected_count}', selectedProductsCount);
      var updatedAtbCtaSingularText = $that.atbCtaSingularText.replace('{selected_count}', selectedProductsCount);
      var updatedAtbCtaNoItemsText = $that.atbCtaNoItemsText.replace('{selected_count}', selectedProductsCount);

      switch (selectedProductsCount) {
        case 0:
          $that.nodes.$atbCtaNoItems.text(updatedAtbCtaNoItemsText);
          $that.nodes.$atbCtaNoItems.parent().removeClass('hidden');
          $that.nodes.$atbCtaSingular.parent().addClass('hidden');
          $that.nodes.$atbCta.parent().addClass('hidden');
          break;
        case 1:
          $that.nodes.$atbCtaSingular.text(updatedAtbCtaSingularText);
          $that.nodes.$atbCtaNoItems.parent().addClass('hidden');
          $that.nodes.$atbCtaSingular.parent().removeClass('hidden');
          $that.nodes.$atbCta.parent().addClass('hidden');
          break;
        default:
          $that.nodes.$atbCta.text(updatedAtbCtaText);
          $that.nodes.$atbCtaNoItems.parent().addClass('hidden');
          $that.nodes.$atbCta.parent().removeClass('hidden');
          $that.nodes.$atbCtaSingular.parent().addClass('hidden');
          break;
      }
    },

    equalHeights: function ($elements) {
      var $tallestElement;

      if ($elements.length < 1) {
        return;
      }

      $elements.height('auto');
      $tallestElement = $(_.max($elements, function(el) { return $(el).height(); }));
      $tallestElement.length > 0 && $elements.css('height', $tallestElement.height());
    },

    updateIsDesktop: function () {
      var self = this;
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();

      self.isDesktop = parseInt(bp.width, 10) >= parseInt(bps['usn-medium-max'], 10);
    },

    addSelectedProductsToBag: function ($that) {
      var selectedSkuIds = [];
      
      $that.nodes.$selectedProducts.each(function () {
        var $selectedProduct = $(this);

        selectedSkuIds.push($selectedProduct.attr('data-sku-base-id'));
      });

      if (selectedSkuIds.length > 0) {
        var params = {
          skus: selectedSkuIds,
          itemType: 'cart',
          INCREMENT: 1,
          CAT_BASE_ID: '',
          QTY: 1
        };

        generic.checkout.cart.updateCart({
          params: params,
          onSuccess: function (response) {
            var messages = response.getMessages();
            var offerCriteriaMessages = '';

            if (messages) {
              messages.forEach(function (message) {
                if (message.key === 'offer_criteria_not_met') {
                  offerCriteriaMessages = offerCriteriaMessages.concat(message.text + '<br />');
                }
              });
            }
            if (offerCriteriaMessages) {
              $(document).trigger('addToCart.failure', [messages]);
              generic.overlay.launch({
                content: offerCriteriaMessages,
                includeBackground: true,
                cssStyle: {
                  width: '324px',
                  padding: '25px'
                }
              });
            } else {
              var resultData = response.getData();

              $(document).trigger('addToCart.success', [resultData]);
            }
          },
          onFailure: function(response) {
            var errorObjectsArray = response.getMessages();
            var prodAddedMsg = $('<div/>').html('<span data-test-id="max_error_message">' + errorObjectsArray[0].text + '</span>').html();

            $(document).trigger('addToCart.failure', [errorObjectsArray]);

            generic.overlay.launch({
              content: prodAddedMsg,
              includeBackground: true,
              cssStyle: {
                width: '324px',
                padding: '25px'
              }
            });
          }
        });
      }
    },

    getFormattedPrice: function ($that, price) {
      var $product = $that.nodes.$product.first();
      var productFormattedPrice = $product.attr('data-formatted-price').replace(/^[USCA]+/, '');
      var rawFormattedPrice = productFormattedPrice.replace(/[^0-9,.]/gu, '');
      var formattedPriceHasDecimal = [',', '.'].includes(rawFormattedPrice?.substr(-3, 1));
      var localeStringOptions = formattedPriceHasDecimal
        ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        : {};
      var productPrice = parseFloat($product.attr('data-price'))
        .toLocaleString(Drupal.settings.price_locale, localeStringOptions);
      var floatPrice = parseFloat(price)
        .toLocaleString(Drupal.settings.price_locale, localeStringOptions);

      return productFormattedPrice.replace(productPrice, floatPrice);
    }
  };
})(jQuery);
